h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.top0 {
  margin-top: 1.7rem;
}

blockquote,
q {
  quotes: none;
}

a[href],
label[for],
select,
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

button,
input[type="button"],
input[type="image"],
input[type="reset"] {
  padding: 0;
  overflow: visible;
  cursor: pointer;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="image"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
  border: 0;
}

/* Normalized Styles
/* a:hover {
  text-decoration: underline;
} */

/* Container
------------------------------------------------------------------------------*/

.container {
  position: relative;
  width: 1170px;
  margin: 0 auto;
}

/* Header
------------------------------------------------------------------------------*/

#header .container {
  height: 93px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
}

#header .right-links {
  float: right;
  padding: 13px 0 0;
  font-family: "Novecentowide";
}

#header .right-links span {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px 12px 0 0;
  background: url("../images/ico_header.png") no-repeat;
}

#header .right-links span.ico-account {
  background-position: -20px 0;
}

#header .right-links span.ico-signout {
  background-position: -40px 0;
}

#header .right-links li {
  float: left;
  color: #656567;
  font-weight: 500;
  padding-left: 37px;
  font-size: 12px;
}

#header .right-links a {
  color: #656567;
}

#logo {
  width: 197px;
  height: 52px;
  display: block;
  float: left;
  cursor: pointer;
  text-indent: -9999em;
  background: url(../images/logo.png) no-repeat;
  margin-top: 0.5rem;
}
.logo-0 {
  font-size: 1.5rem;
  padding: 1rem;
  text-transform: uppercase;
  display: none;
  color: #d3b15b;
}
/* Navigation
------------------------------------------------------------------------------*/

#menu {
  text-align: center;
  color: #000000;
  font-size: 17px;
  font-weight: 900;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: white;
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.104);
}

#menu a {
  color: #3f3f3f;
}

.li-0-contain {
  /* position: fixed; */
  padding: 1rem;
  background-color: white;
  width: 100%;
  z-index: -1;
}
.li-0 {
  text-transform: uppercase;
  /* float: left; */
  font-size: 1.5rem;
}

.hidden a {
  color: #d3b15b;
}

/* Content
------------------------------------------------------------------------------*/

#breadcrumbs {
  background: #383838;
  color: #d9d9d9;
  font-size: 14px;
  overflow: hidden;
  padding: 12px 0;
}

#breadcrumbs li {
  float: left;
}

#breadcrumbs a {
  color: #a5a5a5;
}

#breadcrumbs li + li:before {
  content: "";
  margin: 13px 13px 0 15px;
  float: left;
  background: url(../images/ico_arrow.png) no-repeat;
  width: 9px;
  height: 5px;
}

#body {
  padding: 48px 0 31px;
}

#slider {
  text-align: center;
  position: relative;
  background: #000;
  margin: 0 0rem 2rem;
  border-radius: 20px;
  overflow: hidden;
  background-position: center;
}

#slider .bx-pager {
  position: absolute;
  bottom: 10px;
  z-index: 99;
  left: 0;
  right: 0;
  font-size: 0;
  text-align: center;
}

#slider .bx-pager div {
  padding: 0 2px;
  display: inline-block;
}

#slider .bx-pager a.active {
  background: #fff;
}

#slider .bx-pager a {
  display: inline-block;
  width: 13px;
  text-indent: -999em;
  height: 13px;
  border: 2px solid #fff;
  border-radius: 70px;
}

#slider li {
  color: #fff;
  height: 686px;
  background: no-repeat 50% 0;
  background-size: cover;
}

/* #slider .first {
  background: no-repeat 50% 15%;
  background-size: cover;
} */

#slider h3 {
  color: #a5b0d6;
  font-family: "Novecentowide";
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 4.5px;
  line-height: 28px;
  padding: 80px 0 0;
}

#slider .btn-more {
  width: 112px;
  display: block;
  color: #a5b1d5;
  font-family: "Novecentowide";
  font-size: 10px;
  font-weight: 500;
  margin: 29px auto;
  text-align: center;
  line-height: 27px;
  height: 31px;
  border: 1px solid #a5b1d5;
}

#slider .btn-more:hover {
  text-decoration: none;
  border: 1px solid #fff;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#slider h2 {
  font-family: "Novecentowide";
  color: #fff;
  font-size: 48px;
  line-height: 46px;
  letter-spacing: 9px;
  font-weight: 300;
}

#slider .purple h3 {
  color: #afa6c8;
}

#slider .purple a {
  border-color: #afa6c8;
  color: #afa6c8;
}

#slider .yellow h3 {
  color: #f7c769;
}

#slider .yellow a {
  border-color: #f7c769;
  color: #f7c769;
}

#content {
  overflow: hidden;
}

#content.full {
  float: none;
  width: auto;
}

.products {
  padding-bottom: 1px;
  overflow: hidden;
}

.products h4 {
  color: #939597;
  font-family: "Novecentowide";
  font-size: 24px;
  padding: 0 0 22px;
}

.products .btn-add {
  height: 40px;
  background-color: #cecece;
  display: block;
  color: #656567;
  font-family: "Novecentowide";
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  margin: 0 32px;
}

.products .btn-add:hover {
  background: #656567;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.products h3 {
  color: #656567;
  font-family: "Novecentowide";
  font-size: 12px;
  font-weight: 600;
  margin: 22px 0 0;
}

.products article {
  width: 235px;
  padding: 18px 0 0;
  height: 405px;
  border: 1px solid #d3d3d3;
  background-color: #fff;
  text-align: center;
  float: left;
  margin: 0 0 -1px -1px;
}

.last-products {
  border: 1px solid #d3d3d3;
  margin: 12px 0 60px;
  position: relative;
}

.last-products .products article {
  border-top: 0;
  border-bottom: 0;
  height: 403px;
}

.last-products .bx-next:after {
  content: "";
  position: absolute;
  right: 0;
  margin: 11px 17px;
  top: 0;
  background: url(../images/ico_arrows.png) no-repeat 100% 0;
  width: 8px;
  height: 16px;
  display: block;
}

.last-products .bx-next {
  position: absolute;
  top: 16px;
  right: 17px;
  width: 101px;
  height: 40px;
  display: block;
  text-indent: -999em;
  border: 1px solid #d3d3d3;
}

.last-products .bx-prev:after {
  content: "";
  position: absolute;
  left: 0;
  margin: 11px 17px;
  top: 0;
  background: url(../images/ico_arrows.png) no-repeat;
  width: 8px;
  height: 16px;
  display: block;
}

.last-products .bx-prev {
  position: absolute;
  top: 16px;
  right: 134px;
  width: 101px;
  height: 40px;
  display: block;
  text-indent: -999em;
  border: 1px solid #d3d3d3;
}

.last-products h2 {
  color: #939597;
  border-bottom: 1px solid #d3d3d3;
  font-size: 24px;
  font-family: "Novecentowide";
  font-weight: 200;
  padding: 21px 30px;
}

.total-count h3 {
  letter-spacing: -0.7px;
  color: #666668;
  font-size: 36px;
  padding: 49px 0 57px;
}

.total-count h3 strong {
  font-weight: 600;
}

.pagination li {
  display: inline-block;
  vertical-align: top;
  margin-left: -1px;
}

.pagination li a {
  width: 33px;
  vertical-align: top;
  text-align: center;
  color: #656567;
  font-family: "Novecentowide";
  font-size: 12px;
  font-weight: 500;
  line-height: 29px;
  height: 33px;
  border: 1px solid #cdcdcd;
  background-color: #fff;
  display: inline-block;
}

/* Fonts
------------------------------------------------------------------------------*/
/* 
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Bold.eot");
  src: url("../fonts/Novecentowide-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Novecentowide-Bold.woff") format("woff"),
    url("../fonts/Novecentowide-Bold.ttf") format("truetype"),
    url("../fonts/Novecentowide-Bold.svg#Novecentowide-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Book.eot");
  src: url("../fonts/Novecentowide-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Novecentowide-Book.woff") format("woff"),
    url("../fonts/Novecentowide-Book.ttf") format("truetype"),
    url("../fonts/Novecentowide-Book.svg#Novecentowide-Book") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Medium.eot");
  src: url("../fonts/Novecentowide-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Medium.woff") format("woff"),
    url("../fonts/Novecentowide-Medium.ttf") format("truetype"),
    url("../fonts/Novecentowide-Medium.svg#Novecentowide-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Normal.eot");
  src: url("../fonts/Novecentowide-Normal.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Normal.woff") format("woff"),
    url("../fonts/Novecentowide-Normal.ttf") format("truetype"),
    url("../fonts/Novecentowide-Normal.svg#Novecentowide-Normal") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-DemiBold.eot");
  src: url("../fonts/Novecentowide-DemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-DemiBold.woff") format("woff"),
    url("../fonts/Novecentowide-DemiBold.ttf") format("truetype"),
    url("../fonts/Novecentowide-DemiBold.svg#Novecentowide-DemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Light.eot");
  src: url("../fonts/Novecentowide-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Light.woff") format("woff"),
    url("../fonts/Novecentowide-Light.ttf") format("truetype"),
    url("../fonts/Novecentowide-Light.svg#Novecentowide-Light") format("svg");
  font-weight: 300;
  font-style: normal;
} */

@media (-webkit-min-device-pixel-ratio: 2), (min-reso#lution: 192dpi) {
  #header .right-links span {
    background-image: url(../images/ico_header@2x.png);
    background-size: 60px 20px;
  }

  #breadcrumbs li + li:before {
    background-image: url(../images/ico_arrow@2x.png);
    background-size: cover;
  }
}

@media screen and (max-width: 1000px) {
  .logo-0 {
    display: block;
  }

  #slider h3 {
    padding-top: 10px;
    font-size: 13px;
  }

  #slider h2 {
    font-size: 18px;
    margin: -14px 0 -10px;
    letter-spacing: 1px;
  }

  #slider li {
    height: 350px;
  }

  .last-products h2 {
    padding: 3px 10px 58px;
    font-size: 14px;
    text-align: center;
  }
}
/* /////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////// */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.section-products {
  padding: 80px 0 54px;
}

.section-products .header {
  margin-bottom: 50px;
}

.section-products .header h3 {
  font-size: 1rem;
  color: #fe302f;
  font-weight: 500;
}

.section-products .header h2 {
  font-size: 2.2rem;
  font-weight: 400;
  color: #444444;
}

.section-products .single-product {
  margin-bottom: 26px;
}

.section-products .single-product .part-1 {
  position: relative;
  height: 290px;
  max-height: 290px;
  margin-bottom: 20px;
  overflow: hidden;
}

.section-products .single-product .part-1::before {
  position: absolute;
  background-color: black;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.3s;
}

.section-products #product-1 .part-1::before {
  background: url("http://metrogold.ae/images/product_images/23.jpg") no-repeat
    center;
  background-size: cover;
  transition: all 0.3s;
}

.section-products #product-2 .part-1::before {
  background: url("https://content.jdmagicbox.com/rep/b2b/gold-scrap/gold-scrap-7.jpg?impolicy=queryparam&im=Resize=(360,360),aspect=fit")
    no-repeat center;
  background-size: cover;
}

.section-products #product-3 .part-1::before {
  background: url("../img/red-bike.jpeg") no-repeat center;
  background-size: cover;
}

.section-products #product-4 .part-1::before {
  background: url("https://img.freepik.com/free-photo/photorealistic-money-concept_23-2151027589.jpg?t=st=1704742532~exp=1704746132~hmac=b24f06353db680038f544777bf249604c3361c23cdea39f8979609ba3ce1a253&w=360")
    no-repeat center;
  background-size: cover;
}

.section-products #product-5 .part-1::before {
  background: url("https://www.pamp.com/sites/pamp/files/2022-02/1kg_fortuna.png")
    no-repeat center;
  background-size: cover;
  transition: all 0.3s;
}

.section-products #product-6 .part-1::before {
  background: url("../img/red-bike.jpeg") no-repeat center;
  background-size: cover;
}

.section-products #product-7 .part-1::before {
  background: url("../img/red-bike.jpeg") no-repeat center;
  background-size: cover;
}

.section-products #product-8 .part-1::before {
  background: url("../img/red-bike.jpeg") no-repeat center;
  background-size: cover;
}

.section-products .single-product .part-1 .discount,
.section-products .single-product .part-1 .new {
  position: absolute;
  top: 15px;
  left: 20px;
  color: #ffffff;
  background-color: #fe302f;
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 0.85rem;
}

.section-products .single-product .part-1 .new {
  left: 0;
  background-color: #444444;
}

.section-products .single-product .part-1 ul {
  position: absolute;
  bottom: 0;
  left: 0px;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: bottom 0ms, opacity 0.5s;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 43, 78, 0.454);
}

.section-products .single-product .part-1 ul li h3 {
  font-size: 2rem;
  font-weight: 500;
  margin-top: 25%;
  color: white;
  text-align: center;
  /* margin-right: 1rem; */
  justify-content: center;
  /* box-shadow: 0 2px 20px rgb(50 50 50 / 10%); */
  transition: color 0.2s;
}

.product-title-0 {
  transform: translateY(-500%);
}

@media screen and (max-width: 1199px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 42%;
  }

  .product-title-0 {
    transform: translateY(-200%);
  }

  .product-title-1 {
    transform: translateY(-200%);
  }
}

@media screen and (max-width: 992px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 38%;
  }

  .product-title-0 {
    transform: translateY(-450%);
  }

  .product-title-1 {
    transform: translateY(0%);
  }
}

@media screen and (max-width: 768px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 25%;
  }

  .product-title-0 {
    transform: translateY(0%);
  }
}

@media screen and (max-width: 530px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 28%;
  }
}

@media screen and (max-width: 490px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 30%;
  }
}

@media screen and (max-width: 460px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 32%;
  }
}

@media screen and (max-width: 460px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 34%;
  }
}

@media screen and (max-width: 450px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 34.5%;
  }
}

@media screen and (max-width: 440px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 35.5%;
  }
}

@media screen and (max-width: 430px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 36%;
  }
}

@media screen and (max-width: 420px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 37%;
  }
}

@media screen and (max-width: 410px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 38%;
  }
}

@media screen and (max-width: 400px) {
  .section-products .single-product .part-1 ul li h3 {
    margin-top: 39%;
  }
}

.section-products .single-product .part-1 ul li a:hover {
  color: #fe302f;
}

.section-products .single-product .part-2 .product-title {
  font-size: 1rem;
}

.section-products .single-product .part-2 h4 {
  display: inline-block;
  font-size: 1rem;
}

.section-products .single-product .part-2 .product-old-price {
  position: relative;
  padding: 0 7px;
  margin-right: 2px;
  opacity: 0.6;
}

.section-products .single-product .part-2 .product-old-price::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #444444;
  transform: translateY(-50%);
}
