.top0 {
  margin-top: 1.7rem;
}

input[type="tel"],
input[type="url"],
input[type="password"],
input[type="text"],
input[type="email"],
input[type="reset"],
input[type="button"],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

article,
aside,
details,
figcaption,
figure,
header,
hgroup,
section {
  display: block;
}

img {
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a[href],
label[for],
select,
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

button,
input[type="button"],
input[type="image"],
input[type="reset"] {
  padding: 0;
  overflow: visible;
  cursor: pointer;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="image"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
  border: 0;
}

.hide {
  position: absolute !important;
  left: -9999em !important;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-family: "Novecentowide";
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

del {
  text-decoration: line-through;
}

th,
td {
  vertical-align: top;
}

th {
  font-weight: normal;
  text-align: left;
}

address,
cite,
dfn {
  font-style: normal;
}

abbr,
acronym {
  border-bottom: 1px dotted #999;
  cursor: help;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

textarea {
  overflow: auto;
}

/* Normalized Styles
------------------------------------------------------------------------------*/

body {
  font: 16px/30px Arial, Helvetica, sans-serif;
  color: #949697;
  background: #fff;
  -webkit-text-size-adjust: none;
  min-width: 320px;
}

input,
textarea,
select,
button {
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
  color: #666668;
}

/* a:hover {
  text-decoration: underline;
} */

/* Content
------------------------------------------------------------------------------*/

/* #slider .first {
  background: no-repeat 50% 15%;
  background-size: cover;
} */

.last-products .bx-prev:hover,
.last-products .bx-next:hover {
  background: #d3d3d3;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.last-products h2 {
  color: #939597;
  border-bottom: 1px solid #d3d3d3;
  font-size: 24px;
  font-family: "Novecentowide";
  font-weight: 200;
  padding: 21px 30px;
}

.quick-links {
  overflow: hidden;
  text-align: center;
  font-size: 0;
  margin: 0 -15px 30px;
}

.quick-links article {
  margin: 0 15px;
  display: inline-block;
  width: 370px;
  height: 412px;
}

.quick-links h2 {
  color: #f7c769;
}

.quick-links h4 {
  color: #beb2a5;
  font-family: "Novecentowide";
  font-size: 18px;
  font-weight: normal;
}

.quick-links .table {
  display: table;
  height: 100%;
  width: 100%;
  color: #beb2a5;
}

.quick-links .table:hover {
  text-decoration: none;
}

.quick-links h4 {
  font-family: "Novecentowide";
  font-size: 18px;
  text-transform: uppercase;
}

.quick-links h3 {
  font-family: "Novecentowide";
  font-size: 24px;
}

.quick-links a p {
  font-family: "Novecentowide";
  padding-top: 14px;
  color: #cd9b9f;
  font-size: 18px;
}

.quick-links hr {
  width: 54px;
  height: 2px;
  background-color: #e9e3d5;
  margin: 15px auto 12px;
  border: 0;
  display: block;
}

.quick-links .cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.quick-links .red hr {
  background: #eed3d3;
}

.quick-links .red a h4 {
  padding: 0 0 15px;
  color: #ce9b9f;
}

.quick-links .red a h3 {
  color: #cd9b9f;
  padding: 18px 0 18px;
  font-size: 30px;
}

.quick-links .red .text {
  width: 264px;
  padding: 50px 0;
  box-shadow: inset 0 0 0 2px #d59ca1;
}

.quick-links .text {
  margin: auto;
  padding: 20px 0 30px;
  width: 312px;
  border: 4px solid #fff;
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #d6cfc3;
}

h1 {
  color: #656567;
  font-size: 36px;
  padding-bottom: 17px;
  line-height: normal;
}

.entry h2 {
  color: #656567;
  font-size: 36px;
  padding: 18px 0 17px;
  line-height: normal;
}

/* Fonts
------------------------------------------------------------------------------*/
/* 
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Bold.eot");
  src: url("../fonts/Novecentowide-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Novecentowide-Bold.woff") format("woff"),
    url("../fonts/Novecentowide-Bold.ttf") format("truetype"),
    url("../fonts/Novecentowide-Bold.svg#Novecentowide-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Book.eot");
  src: url("../fonts/Novecentowide-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Novecentowide-Book.woff") format("woff"),
    url("../fonts/Novecentowide-Book.ttf") format("truetype"),
    url("../fonts/Novecentowide-Book.svg#Novecentowide-Book") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Medium.eot");
  src: url("../fonts/Novecentowide-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Medium.woff") format("woff"),
    url("../fonts/Novecentowide-Medium.ttf") format("truetype"),
    url("../fonts/Novecentowide-Medium.svg#Novecentowide-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Normal.eot");
  src: url("../fonts/Novecentowide-Normal.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Normal.woff") format("woff"),
    url("../fonts/Novecentowide-Normal.ttf") format("truetype"),
    url("../fonts/Novecentowide-Normal.svg#Novecentowide-Normal") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-DemiBold.eot");
  src: url("../fonts/Novecentowide-DemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-DemiBold.woff") format("woff"),
    url("../fonts/Novecentowide-DemiBold.ttf") format("truetype"),
    url("../fonts/Novecentowide-DemiBold.svg#Novecentowide-DemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Light.eot");
  src: url("../fonts/Novecentowide-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Light.woff") format("woff"),
    url("../fonts/Novecentowide-Light.ttf") format("truetype"),
    url("../fonts/Novecentowide-Light.svg#Novecentowide-Light") format("svg");
  font-weight: 300;
  font-style: normal;
} */

/* ------------  jwl   ------------- */

.jwl {
  height: 800px;
  margin: 2rem 1rem;
  border-radius: 20px;
  background-size: cover;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
}

/* ------------  first-0   ------------- */
.first-0 {
  text-align: center;
  max-width: 97vw;
}

.first-0 h2 {
  font-size: 2.5rem;
  margin: 9rem 0 1.5rem;
  color: rgb(108, 106, 0);
}

.first-0 p {
  font-size: 1.5rem;
  margin: 1.5rem 0 2rem;
}

.p-0 p {
  margin-top: -0.7rem;
  margin-left: -0.2rem;
}

.p-0 {
  display: flex;
}

.img-box1 {
  height: 400px;
  /* width: 900px; */
  margin: auto;
  overflow: hidden;
  border-radius: 20px;
}

.img-box1 img {
  height: 400px;
}

.img-box1 img {
  width: 100%;
}

.text-box1 {
  height: 100%;
  padding: 0 2rem;
  background-color: rgba(255, 255, 255, 0.975);
  margin: 0 0rem 0.5rem;
}

.text-box-0 {
  padding-top: 2rem;
}

.text-box1 h2 {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(186, 136, 0);
}

.iner-box {
  position: absolute;
  background-color: rgb(186, 136, 0);
  height: 0.4rem;
  width: 100px;
}

.text-box1 p {
  padding-top: 1.5rem;
  text-align: justify;
}

@media screen and (max-width: 552px) {
  .img-box1 {
    height: 200px;
    /* width: 10vw; */
    overflow: hidden;
    border-radius: 20px;
  }
}
@media screen and (max-width: 992px) {
  .img-box1 {
    height: 400px;
    width: 90%;
    overflow: hidden;
    border-radius: 20px;
  }

  .img-box1 img {
    height: 400px;
    overflow: hidden;
  }

  .img-box1 img {
    width: 100%;
  }
}
