/* @keyframes bend {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
} */

.call-contain {
  z-index: 111;
}

.bending-image {
  width: 70px;
  border: 0px;
  height: 70px;
  /* animation: bend 0.5s infinite; */
}
