.top0 {
  margin-top: 1.5rem;
}

@media screen and (max-width: 1220px) {
  .top0 {
    margin-top: 3.2rem;
  }
}

input[type="tel"],
input[type="url"],
input[type="password"],
input[type="text"],
input[type="email"],
input[type="reset"],
input[type="button"],
button,
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a[href],
label[for],
select,
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

input[type="image"],
input[type="reset"] {
  padding: 0;
  overflow: visible;
  cursor: pointer;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="image"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
  border: 0;
}

.hide {
  position: absolute !important;
  left: -9999em !important;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-family: "Novecentowide";
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

del {
  text-decoration: line-through;
}

th,
td {
  vertical-align: top;
}

th {
  font-weight: normal;
  text-align: left;
}

address,
cite,
dfn {
  font-style: normal;
}

abbr,
acronym {
  border-bottom: 1px dotted #999;
  cursor: help;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

textarea {
  overflow: auto;
}

/* Normalized Styles
------------------------------------------------------------------------------*/

body {
  font: 16px/30px Arial, Helvetica, sans-serif;
  color: #949697;
  background: #fff;
  -webkit-text-size-adjust: none;
  min-width: 320px;
}

input,
textarea,
select,
button {
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
  color: #666668;
}

/* a:hover {
  text-decoration: underline;
} */

/* Container
------------------------------------------------------------------------------*/

.container {
  position: relative;
  width: 1170px;
  margin: 0 auto;
}

/* Header
------------------------------------------------------------------------------*/

#header .container {
  height: 93px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
}

#header .right-links {
  float: right;
  padding: 13px 0 0;
  font-family: "Novecentowide";
}

#header .right-links span {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px 12px 0 0;
  background: url(../images/ico_header.png) no-repeat;
}

#header .right-links span.ico-account {
  background-position: -20px 0;
}

#header .right-links span.ico-signout {
  background-position: -40px 0;
}

#header .right-links li {
  float: left;
  color: #656567;
  font-weight: 500;
  padding-left: 37px;
  font-size: 12px;
}

#header .right-links a {
  color: #656567;
}

#logo {
  width: 197px;
  height: 52px;
  display: block;
  float: left;
  cursor: pointer;
  text-indent: -9999em;
  background: url(../images/logo.png) no-repeat;
  margin-top: 0.5rem;
}
.logo-0 {
  font-size: 1.5rem;
  padding: 1rem;
  text-transform: uppercase;
  display: none;
  color: #d3b15b;
}
/* Navigation
------------------------------------------------------------------------------*/

#menu {
  text-align: center;
  color: #000000;
  font-size: 17px;
  font-weight: 900;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: white;
  width: 100%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

#menu li {
  padding: 19px 19px 21px;
  display: inline-block;
  font-family: "Novecentowide";
}

#menu a {
  color: #3f3f3f;
}

.li-0-contain {
  /* position: fixed; */
  padding: 1rem;
  background-color: white;
  width: 100%;
  z-index: -1;
}
.li-0 {
  text-transform: uppercase;
  /* float: left; */
  font-size: 1.5rem;
}

.hidden a {
  color: #d3b15b;
}

/* Content
------------------------------------------------------------------------------*/

#breadcrumbs {
  background: #383838;
  color: #d9d9d9;
  font-size: 14px;
  overflow: hidden;
  padding: 12px 0;
}

#breadcrumbs li {
  float: left;
}

#breadcrumbs a {
  color: #a5a5a5;
}

#breadcrumbs li + li:before {
  content: "";
  margin: 13px 13px 0 15px;
  float: left;
  background: url(../images/ico_arrow.png) no-repeat;
  width: 9px;
  height: 5px;
}

#body {
  padding: 48px 0 31px;
}

#slider {
  text-align: center;
  position: relative;
  background: #000;
  margin: 0 0rem 2rem;
  border-radius: 20px;
  overflow: hidden;
  background-position: center;
}

#slider .bx-pager {
  position: absolute;
  bottom: 10px;
  z-index: 99;
  left: 0;
  right: 0;
  font-size: 0;
  text-align: center;
}

#slider .bx-pager div {
  padding: 0 2px;
  display: inline-block;
}

#slider .bx-pager a.active {
  background: #fff;
}

#slider .bx-pager a {
  display: inline-block;
  width: 13px;
  text-indent: -999em;
  height: 13px;
  border: 2px solid #fff;
  border-radius: 70px;
}

#slider li {
  color: #fff;
  height: 686px;
  background: no-repeat 50% 0;
  background-size: cover;
}

/* #slider .first {
  background: no-repeat 50% 15%;
  background-size: cover;
} */

#slider h3 {
  color: #a5b0d6;
  font-family: "Novecentowide";
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 4.5px;
  line-height: 28px;
  padding: 80px 0 0;
}

#slider .btn-more {
  width: 112px;
  display: block;
  color: #a5b1d5;
  font-family: "Novecentowide";
  font-size: 10px;
  font-weight: 500;
  margin: 29px auto;
  text-align: center;
  line-height: 27px;
  height: 31px;
  border: 1px solid #a5b1d5;
}

#slider .btn-more:hover {
  text-decoration: none;
  border: 1px solid #fff;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#slider h2 {
  font-family: "Novecentowide";
  color: #fff;
  font-size: 48px;
  line-height: 46px;
  letter-spacing: 9px;
  font-weight: 300;
}

#slider .purple h3 {
  color: #afa6c8;
}

#slider .purple a {
  border-color: #afa6c8;
  color: #afa6c8;
}

#slider .yellow h3 {
  color: #f7c769;
}

#slider .yellow a {
  border-color: #f7c769;
  color: #f7c769;
}

#content {
  overflow: hidden;
}

#content.full {
  float: none;
  width: auto;
}

.products {
  padding-bottom: 1px;
  overflow: hidden;
}

.products h4 {
  color: #939597;
  font-family: "Novecentowide";
  font-size: 24px;
  padding: 0 0 22px;
}

.products .btn-add {
  height: 40px;
  background-color: #cecece;
  display: block;
  color: #656567;
  font-family: "Novecentowide";
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  margin: 0 32px;
}

.products .btn-add:hover {
  background: #656567;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.products h3 {
  color: #656567;
  font-family: "Novecentowide";
  font-size: 12px;
  font-weight: 600;
  margin: 22px 0 0;
}

.products article {
  width: 235px;
  padding: 18px 0 0;
  height: 405px;
  border: 1px solid #d3d3d3;
  background-color: #fff;
  text-align: center;
  float: left;
  margin: 0 0 -1px -1px;
}

.last-products {
  border: 1px solid #d3d3d3;
  margin: 12px 0 60px;
  position: relative;
}

.last-products .products article {
  border-top: 0;
  border-bottom: 0;
  height: 403px;
}

.last-products .bx-next:after {
  content: "";
  position: absolute;
  right: 0;
  margin: 11px 17px;
  top: 0;
  background: url(../images/ico_arrows.png) no-repeat 100% 0;
  width: 8px;
  height: 16px;
  display: block;
}

.last-products .bx-next {
  position: absolute;
  top: 16px;
  right: 17px;
  width: 101px;
  height: 40px;
  display: block;
  text-indent: -999em;
  border: 1px solid #d3d3d3;
}

.last-products .bx-prev:after {
  content: "";
  position: absolute;
  left: 0;
  margin: 11px 17px;
  top: 0;
  background: url(../images/ico_arrows.png) no-repeat;
  width: 8px;
  height: 16px;
  display: block;
}

.last-products .bx-prev {
  position: absolute;
  top: 16px;
  right: 134px;
  width: 101px;
  height: 40px;
  display: block;
  text-indent: -999em;
  border: 1px solid #d3d3d3;
}

.last-products .bx-prev:hover,
.last-products .bx-next:hover {
  background: #d3d3d3;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.last-products h2 {
  color: #939597;
  border-bottom: 1px solid #d3d3d3;
  font-size: 24px;
  font-family: "Novecentowide";
  font-weight: 200;
  padding: 21px 30px;
}

.quick-links {
  overflow: hidden;
  text-align: center;
  font-size: 0;
  margin: 0 -15px 30px;
}

.quick-links article {
  margin: 0 15px;
  display: inline-block;
  width: 370px;
  height: 412px;
}

.quick-links h2 {
  color: #f7c769;
}

.quick-links h4 {
  color: #beb2a5;
  font-family: "Novecentowide";
  font-size: 18px;
  font-weight: normal;
}

.quick-links .table {
  display: table;
  height: 100%;
  width: 100%;
  color: #beb2a5;
}

.quick-links .table:hover {
  text-decoration: none;
}

.quick-links h4 {
  font-family: "Novecentowide";
  font-size: 18px;
  text-transform: uppercase;
}

.quick-links h3 {
  font-family: "Novecentowide";
  font-size: 24px;
}

.quick-links a p {
  font-family: "Novecentowide";
  padding-top: 14px;
  color: #cd9b9f;
  font-size: 18px;
}

.quick-links hr {
  width: 54px;
  height: 2px;
  background-color: #e9e3d5;
  margin: 15px auto 12px;
  border: 0;
  display: block;
}

.quick-links .cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.quick-links .red hr {
  background: #eed3d3;
}

.quick-links .red a h4 {
  padding: 0 0 15px;
  color: #ce9b9f;
}

.quick-links .red a h3 {
  color: #cd9b9f;
  padding: 18px 0 18px;
  font-size: 30px;
}

.quick-links .red .text {
  width: 264px;
  padding: 50px 0;
  box-shadow: inset 0 0 0 2px #d59ca1;
}

.quick-links .text {
  margin: auto;
  padding: 20px 0 30px;
  width: 312px;
  border: 4px solid #fff;
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #d6cfc3;
}

h1 {
  color: #656567;
  font-size: 36px;
  padding-bottom: 17px;
  line-height: normal;
}

.entry h2 {
  color: #656567;
  font-size: 36px;
  padding: 18px 0 17px;
  line-height: normal;
}

.entry a {
  text-decoration: underline;
}

/* .entry a:hover {
  text-decoration: none;
} */

.entry p {
  padding-bottom: 20px;
}

.entry ol {
  border-left: 5px solid #949697;
  margin: 0 0 30px 31px;
  padding: 5px 10px 0;
  counter-reset: my-counter;
}

.entry ol li:before {
  font-weight: bold;
  padding-right: 10px;
  content: counter(my-counter) ".";
  counter-increment: my-counter;
}

.entry li:first-child {
  padding-top: 0;
}

.entry li {
  padding-top: 12px;
  line-height: 24px;
}

.total-count {
  display: block;
  max-width: 568px;
  border: 1px solid #d3d3d3;
  margin: 48px auto 30px;
  font-family: "Novecentowide";
  text-align: center;
  font-size: 18px;
  color: #949697;
  padding-bottom: 35px;
  line-height: 20px;
}

.total-count h4 {
  color: #939597;
  font-size: 24px;
  padding: 32px 0 12px;
}

.btn-grey {
  display: inline-block;
  padding: 0 55px;
  line-height: 53px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  height: 53px;
  font-family: "Novecentowide";
  background-color: #373737;
}

.btn-grey:hover {
  opacity: 0.9;
  text-decoration: none;
}

.total-count h3 {
  letter-spacing: -0.7px;
  color: #666668;
  font-size: 36px;
  padding: 49px 0 57px;
}

.total-count h3 strong {
  font-weight: 600;
}

.cart-table {
  padding: 13px 0;
}

.cart-table th {
  background: #383838;
  border: 1px solid #383838;
  text-align: center;
  border-top: 0;
  color: #fff;
  line-height: 40px;
  height: 43px;
  font-family: "Novecentowide";
  font-size: 12px;
  font-weight: 500;
  text-shadow: -1px -1px 0 rgba(12, 13, 13, 0.5);
}

.cart-table td {
  font-size: 15px;
  line-height: 24px;
  color: #949697;
  border: 1px solid #d3d3d3;
  padding: 15px 15px 14px;
  vertical-align: middle;
}

.cart-table img {
  float: left;
  padding-right: 20px;
}

.cart-table h3 {
  color: #656567;
  font-family: "Novecentowide";
  font-size: 13px;
  padding: 5px 0;
  font-weight: 600;
}

.product h1 {
  color: #656567;
  font-family: "Novecentowide";
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  padding: 5px 0;
}

.product p {
  line-height: 27px;
  font-size: 16px;
}

.entry .nav {
  border-right: 1px solid #cecece;
  overflow: hidden;
  margin: 13px 0 0 0;
}

.entry .nav ul {
  margin: 0 -1px 0 0;
  overflow: hidden;
}

.entry .nav li:first-child {
  border-left: 1px solid #cecece;
}

.entry .nav li:last-child {
  border-right: 0;
  padding-right: 20px;
  margin-right: -20px;
}

.entry .nav li:last-child a {
  border-right: 0;
  padding-right: 50px;
  margin-right: -50px;
}

.entry .nav li {
  border: 1px solid #cecece;
  float: left;
  border-left: 0;
  background: #f2f2f2;
  width: 33.3%;
  padding: 0;
}

.entry .nav a {
  display: block;
  line-height: 38px;
  text-align: center;
  padding: 0 20px;
  height: 38px;
  text-decoration: none;
  color: #656567;
  font-family: "Novecentowide";
  font-size: 12px;
  font-weight: 500;
}

.entry .nav .active {
  position: relative;
  z-index: 99;
  background: #fff;
  border-bottom-color: #fff;
}

.entry .tab-content p {
  padding-bottom: 11px;
}

.entry .tab-content {
  display: none;
  padding: 13px 29px 0;
  margin-bottom: 39px;
  margin-top: -1px;
  border: 1px solid #cdcdcd;
}

.entry .tab-content.active {
  display: block;
}

.details .actions a {
  float: right;
  padding: 0 44px;
  line-height: 40px;
  height: 40px;
}

.pagination span {
  display: inline-block;
  background: url(../images/ico_pagination.png) no-repeat;
  width: 30px;
  height: 30px;
  margin: 1px;
}

.pagination a:hover,
.pagination .active a {
  text-decoration: none;
  background-color: #cecece;
}

.pagination li a {
  width: 33px;
  vertical-align: top;
  text-align: center;
  color: #656567;
  font-family: "Novecentowide";
  font-size: 12px;
  font-weight: 500;
  line-height: 29px;
  height: 33px;
  border: 1px solid #cdcdcd;
  background-color: #fff;
  display: inline-block;
}

/* Fonts
------------------------------------------------------------------------------*/
/* 
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Bold.eot");
  src: url("../fonts/Novecentowide-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Novecentowide-Bold.woff") format("woff"),
    url("../fonts/Novecentowide-Bold.ttf") format("truetype"),
    url("../fonts/Novecentowide-Bold.svg#Novecentowide-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Book.eot");
  src: url("../fonts/Novecentowide-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Novecentowide-Book.woff") format("woff"),
    url("../fonts/Novecentowide-Book.ttf") format("truetype"),
    url("../fonts/Novecentowide-Book.svg#Novecentowide-Book") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Medium.eot");
  src: url("../fonts/Novecentowide-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Medium.woff") format("woff"),
    url("../fonts/Novecentowide-Medium.ttf") format("truetype"),
    url("../fonts/Novecentowide-Medium.svg#Novecentowide-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Normal.eot");
  src: url("../fonts/Novecentowide-Normal.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Normal.woff") format("woff"),
    url("../fonts/Novecentowide-Normal.ttf") format("truetype"),
    url("../fonts/Novecentowide-Normal.svg#Novecentowide-Normal") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-DemiBold.eot");
  src: url("../fonts/Novecentowide-DemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-DemiBold.woff") format("woff"),
    url("../fonts/Novecentowide-DemiBold.ttf") format("truetype"),
    url("../fonts/Novecentowide-DemiBold.svg#Novecentowide-DemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Novecentowide";
  src: url("../fonts/Novecentowide-Light.eot");
  src: url("../fonts/Novecentowide-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Novecentowide-Light.woff") format("woff"),
    url("../fonts/Novecentowide-Light.ttf") format("truetype"),
    url("../fonts/Novecentowide-Light.svg#Novecentowide-Light") format("svg");
  font-weight: 300;
  font-style: normal;
} */

/* Media
------------------------------------------------------------------------------*/

#header .right-links span {
  background-image: url(../images/ico_header@2x.png);
  background-size: 60px 20px;
}

#footer form button {
  background-image: url(../images/ico_plus@2x.png);
  background-size: 16px 16px;
}

.pagination span {
  background-image: url(../images/ico_pagination@2x.png);
  background-size: 60px 30px;
}

@media screen and (max-width: 1220px) {
  .container {
    width: auto;
    padding: 0 20px;
  }

  .products-wrap #content {
    font-size: 0;
    text-align: center;
  }

  #header .container {
    height: auto;
  }

  .quick-links .text {
    margin: 0 10px;
    width: auto !important;
    display: block;
  }

  .details .actions a {
    float: none;
    width: 100%;
    margin: 10px 0 0;
    text-align: center;
  }

  .total-count h3 {
    line-height: normal;
    font-size: 24px;
  }

  #menu .container {
    position: static;
  }

  #header .container {
    height: auto;
    padding-top: 10px;
    padding-bottom: 8px;
  }

  #header .container:after {
    content: "";
    display: block;
    clear: both;
  }

  .entry ol {
    margin-left: 0;
  }

  .entry h2,
  h1 {
    font-size: 20px;
    line-height: normal;
  }

  .entry {
    font-size: 16px;
    line-height: 28px;
  }

  .entry li {
    font-size: 14px;
  }

  #body {
    padding: 20px 0;
  }

  .total-count h3 strong {
    display: block;
  }
}

/* ------------  first-0   ------------- */

.first-0 p {
  font-size: 1.5rem;
  margin: 1.5rem 0 2rem;
}

.p-0 p {
  margin-top: -0.7rem;
  margin-left: -0.2rem;
}

.hidden a:hover {
  text-decoration: none;
  border-bottom: none;
  padding-bottom: 0;
}

.box-0 {
  background-image: url(https://static.businessnews.com.au/sites/default/files/Aditya-Birla_A.jpg);
  background-position: center;
  background-size: cover;
}

.text-box0 {
  height: 100%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.713);
  /* margin: 3rem -10rem 0 0; */
  /* z-index: 0; */
}

.text-box0 h1 {
  text-align: center;
  color: white;
  font-weight: 900;
}

.text-box1 h3 {
  font-weight: 800;
  color: rgb(53, 53, 53);
}

.p {
  margin: 1rem 0;
}

/* ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////// */

.submit-button {
  padding: 1rem;
  border-radius: 20px;
}

.iframe-0 {
  border: 0;
  margin: 100px 0 20px;
}
