.active {
  border-bottom: 0.2rem solid #d3b15b;
  padding-bottom: 0.6rem;
  bottom: -4px;
  left: 20px;
}

.logo {
  width: 10rem;
}

nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  height: 70px;
  /* background-color: rgba(255, 255, 255, 0.1); */
  /* backdrop-filter: blur(20px); */
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  padding: 10px 80px -1rem;
  z-index: 100;
  width: 100%;
  top: 0;
  overflow: hidden;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  margin-bottom: -1rem;
  position: relative;
}

#navbar li .link {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: #3f3f3f;
  transition: 5ms ease-in-out;
  transition: padding-left 200ms;
}

#navbar li .link:not(.active):hover {
  border-bottom: 0.2rem solid #d3b15b;
  padding-bottom: 0.6rem;
  bottom: -4px;
  left: 20px;
}

#mobile {
  display: none;
}

#mobile i {
  color: #3f3f3f;
  margin: 21.3px 0;
}

.link i {
  display: none;
  padding: 0px 10px 0px 0px;
  color: #4c4c4c;
}

@media screen and (max-width: 896px) {
  #mobile {
    display: block;
    cursor: pointer;
  }

  #navbar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 0px;
    right: -210px;
    width: 200px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  /* .backdrop {
      position: fixed;
      top: 0px;
      right: -300px;
      width: 550px;
      height: 100vh;
      background-color: rgb(0, 0, 0);
      box-shadow: 0 40px 60px rgb(0, 0, 0);
      backdrop-filter: blur(20px);
      padding: 40px 0 0 10px;
    } */

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile i {
    position: absolute;
    font-size: 24px;
    cursor: pointer;
    margin-top: -15px;
    right: 20px;
  }

  .link i {
    display: inline-block;
  }

  #navbar.active1 {
    right: 0px;
    top: 0;
    width: 200px;
  }
  .logo-contain {
    margin: auto;
    margin-top: -10px;
  }

  .active {
    border-left: 0.2rem solid #d3b15b;
    padding-bottom: 0rem;
    border-bottom: 0px;
    padding-left: 10px;
    bottom: -4px;
    left: 20px;
  }

  #navbar li .link:not(.active):hover {
    border-left: 0.2rem solid #d3b15b;
    border-bottom: none;
    padding-left: 10px;
    padding-bottom: 0px;
  }
}

.navbar {
  position: fixed;
  margin-top: -110px;
  right: 0;
}

.link #a-1 {
  padding: 0px 13.2px 0px 0px;
}

.link #a-2 {
  padding: 0px 16.3px 0px 0px;
}

.link #a-3 {
  padding: 0px 13.3px 0px 0px;
}

.logo h3 {
  font-weight: 900;
  color: #d3b15b;
  font-size: 1.5rem;
  margin-top: 20px;
}
